<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="1200px"
    >
      <div style="margin-bottom: 20px">
        <span>时间：{{ showData() }}</span>
      </div>

      <el-table v-if="visible" v-loading="loading" :data="list" :default-sort="sort" border @sort-change="handleSortChange">
        <el-table-column type="index" label="排行" width="70" align="center" :index="(index)=> (index+1) + (pageNum-1)*pageSize" />
        <el-table-column label="回收商家" prop="dataName" align="center" />
        <el-table-column label="接收询价量" prop="inquiryCount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="报价量" prop="quoteCount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="拒绝报价量" prop="rejectQuoteNum" sortable="custom" :sort-orders="['descending', 'ascending']" align="center">
          <template slot-scope="{ row }">
            <el-link v-if="row.rejectQuoteNum" type="danger" :underline="false">{{row.rejectQuoteNum}}</el-link>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="低价拒绝量" prop="rejectLowQuoteNum" sortable="custom" :sort-orders="['descending', 'ascending']" align="center">
          <template slot-scope="{ row }">
            <el-link v-if="row.rejectLowQuoteNum" type="danger" :underline="false">{{row.rejectLowQuoteNum}}</el-link>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="有效报价量" prop="normalQuoteNum" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="成交量" prop="dataCount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="成交率" prop="dataRate" align="center" sortable="custom" :sort-orders="['descending', 'ascending']">
          <template slot-scope="{ row }">
            {{ row.dataRate }}%
          </template>
        </el-table-column>
        <el-table-column label="报价率" prop="quoteRate" align="center" sortable="custom" :sort-orders="['descending', 'ascending']">
          <template slot-scope="{ row }">
            {{ row.quoteRate }}%
          </template>
        </el-table-column>
        <el-table-column label="交易金额" prop="dealAmount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      </el-table>
      <Pagination :pageNum.sync="pageNum" :pageSize.sync="pageSize" :total="total" @pagination="getData" />
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { brandOrPriceOrderDownTypes, getSortForm } from '../table/define'
import Pagination from '../Pagination.vue';

export default {
  name: "BrandOrPriceMerchantDia",
  components: { Pagination },
  inject: ['showData'],
  props: {
    title: String,
    form: {
      type: Object,
      default: () => ({})
    },
    sort: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      sortForm: {},
      row: {}
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.sortForm = getSortForm(this.sort, brandOrPriceOrderDownTypes)
      this.pageNum = 1
      this.visible = true
      this.getData()
    },
    getData() {
      this.loading = true
      _api.brandPriceRankDown({
        ...this.form,
        ...this.sortForm,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        key: this.row.key,
        channel: 'merchant'
      }).then(res => {
        this.list = res.data.records
        this.total = res.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    handleSortChange({ order, prop }) {
      // this.sort = { order, prop }

      const sortForm = getSortForm({ order, prop }, brandOrPriceOrderDownTypes)
      this.sortForm = sortForm
      this.getData()
    },
  }
};
</script>