<template>
  <div class="Settle">
   <div class="left shopSettle">
       <div class="Recycle_title">
           <div>
                <img class="case-title_ico" src="@/assets/images/imgAll/icon_sjlb_.png" alt />
           </div>
           <div>
               回收商入驻
           </div>
       </div>
       <div class="Recycle_number">
           <div class="Fir_day numDay">
               <div>{{HomeDataList.todayMerchantCount}}</div>
               <div>今日入驻</div>
           </div>
           <div class="Sec_week numDay">
               <div>{{HomeDataList.weekMerchantCount}}</div>
               <div>本周入驻</div>
           </div>
           <div class="Fir_month numDay">
               <div>{{HomeDataList.monthMerchantCount}}</div>
               <div>本月入驻</div>
           </div>
       </div>
   </div>
   <div class="right shopSettle">
       <div class="Recycle_title">
           <div>
                <img class="case-title_ico" src="@/assets/images/imgAll/icon_sjlb_.png" alt />
           </div>
           <div>
               门店商入驻
           </div>
       </div>
       <div class="Recycle_number">
           <div class="Fir_day numDay">
               <div>{{HomeDataList.todayCompanyCount}}</div>
               <div>今日入驻</div>
           </div>
           <div class="Sec_week numDay">
               <div>{{HomeDataList.weekCompanyCount}}</div>
               <div>本周入驻</div>
           </div>
           <div class="Fir_month numDay">
               <div>{{HomeDataList.monthCompanyCount}}</div>
               <div>本月入驻</div>
           </div>
       </div>
    </div>
  </div>
</template>
<script>
import _api from "@/utils/request";
export default {
  name: 'Settle',
  data(){
    return{
        HomeDataList:{}
    }
  },
  created(){
   this.platformJoinMCDataStatistics()
  },
  methods:{
    platformJoinMCDataStatistics() {
      _api.platformJoinMCDataStatistics().then((res) => {
        if (res.code === 1) {
          this.HomeDataList = res.data;
        }
      });
    },
  }
 
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Settle{
    display: flex;
    justify-content: space-between;
    .shopSettle{
        display: flex;
        width: 49.5%;
        height: 160px;
        background: #FFFFFF;
        border: 4px solid #FFFFFF;
        box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
        border-radius: 10px;
        .Recycle_title{
            width: 20%;
            height: 150px;
            background: #EDF2FE;
            border-radius: 10px;
            padding-top: 20px;
            div:first-child{
                width: 80px;
                margin: 0 auto;
            }
            div:last-child{
                width: 100px;
                margin: 0 auto;
                height: 21px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 41px;
            }
        }
        .Recycle_number{
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 150px;
            width: 80%;
            .Fir_day{
                color: #0981FF;
            }
            .Sec_week{
                color: #FFAF29;
            }
            .Fir_month{
                color: #FF687B;
            }
            .numDay{
                width: 20%;
                height: 100%;
                padding-top: 5%;
                div:first-child{
                    margin: auto;
                    width: 37px;
                    height: 27px;
                    font-size: 36px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    line-height: 41px;
                    margin-bottom: 20px;
                }
                div:last-child{
                    margin: 0 auto;
                    width: 80px;
                    height: 21px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 41px;
                }
            }
        }
    }
}
</style>
