<template>
  <div>
    <el-table v-loading="loading" :data="list" border max-height="540" style="width: 100%" @sort-change="handleSortChange">
      <el-table-column prop="rank" label="排行" width="70" align="center" />
      <el-table-column prop="dataName" :label="type === '02' ? '品牌' : '价位段'" align="center" />
      <el-table-column prop="assessCount" label="系统估价量" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="inquiryCount" label="询价量" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="dataCount" label="成交量" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="dataRate" label="成交率" align="center" sortable="custom" :sort-orders="['descending', 'ascending']">
        <template slot-scope="{ row }">
          <span>{{ row.dataRate }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealAmount" label="累计交易金额（元）" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="avgPrice" label="平均成交单价（元）" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column label="回收商交易排行" align="center">
        <template slot-scope="{ row }">
          <!-- <el-link type="primary" @click="merchantDialog(row, 'merchant')">查看</el-link> -->
          <el-link type="primary" @click="$refs['brandOrPriceMerchantDia'].open(row)">查看</el-link>
        </template>
      </el-table-column>
      <el-table-column label="门店商交易排行" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="$refs['brandOrPriceStoreDia'].open(row)">查看</el-link>
        </template>
      </el-table-column>
    </el-table>
    <BrandOrPriceMerchantDia ref="brandOrPriceMerchantDia" :title="type == '01' ? '价位段排行' : '品牌排行'" :form="form" :sort="sort" />
    <BrandOrPriceStoreDia ref="brandOrPriceStoreDia" :title="type == '01' ? '价位段排行' : '品牌排行'" :form="form" :sort="sort" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import { brandOrPriceOrderTypes, getSortForm } from './define'
import BrandOrPriceMerchantDia from '../dialog/BrandOrPriceMerchantDia.vue';
import BrandOrPriceStoreDia from '../dialog/BrandOrPriceStoreDia.vue';
export default {
  name: 'BrandOrPriceRankTable',
  components: { BrandOrPriceMerchantDia, BrandOrPriceStoreDia },
  props: {
    type: String,
  },
  data() {
    return {
      loading: false,
      list: [],
      form: {},
      sortForm: {},
      sort: {},
    }
  },
  methods: {
    init(form) {
      this.form = JSON.parse(JSON.stringify(form))
      this.getData()
    },
    getData() {
      this.loading = true
      _api.brandPriceRankList({
        ...this.form,
        ...this.sortForm,
        type: this.type
      }).then(res => {
        this.list = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    handleSortChange({ order, prop }) {
      this.sort = { order, prop }
      const sortForm = getSortForm({ order, prop }, brandOrPriceOrderTypes)
      this.sortForm = sortForm
      this.getData()
    },
    merchantDialog() {},
    storeDialog() {}
  }
}
</script>