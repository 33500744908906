<template>
  <div>
    <el-table max-height="540" :data="list" border style="width: 100%" v-loading="loading" @sort-change="handleSortChange">
      <el-table-column prop="rank" label="排行" width="70" align="center" />
      <el-table-column prop="modelId" label="机型ID" align="center"></el-table-column>
      <el-table-column prop="modelName" label="机型" align="center"></el-table-column>
      <el-table-column prop="brandName" label="所属品牌" align="center"></el-table-column>
      <el-table-column prop="assessCount" label="系统估价量" align="center" />
      <el-table-column prop="assessToInquiryRate" align="center" width="140px">
        <template slot="header">
          估价单询价转化率
          <el-tooltip class="item" effect="dark" content="估价后询价下单量/估价量" placement="right">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </template>
        <template slot-scope="{ row }">
          <span>{{ row.assessToInquiryRate }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="assessToDealRate" align="center" width="140px">
        <template slot="header">
          估价单成交转化率
          <el-tooltip class="item" effect="dark" content="估价后询价且成交的订单量/估价量" placement="right">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </template>
        <template slot-scope="{ row }">
          <span>{{ row.assessToDealRate }}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="inquiryCount" label="询价量" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="dealCount" label="成交量" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
      <el-table-column prop="dealRate" label="成交率" align="center" sortable="custom" :sort-orders="['descending', 'ascending']">
        <template slot-scope="{ row }">
          <span>{{ row.dealRate }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180px">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="$refs['modelTankHighDia'].open(row)">报价分布</el-link>
          <el-link type="primary" @click="$refs['modelTankStoreDia'].open(row)" style="margin-left: 20px">询价分布</el-link>
        </template>
      </el-table-column>
    </el-table>
    <ModelTankHighDia ref="modelTankHighDia" :sort="sort" :form="form" />
    <ModelTankStoreDia ref="modelTankStoreDia" :sort="sort" :form="form" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import ModelTankHighDia from '../dialog/ModelTankHighDia.vue';
import ModelTankStoreDia from '../dialog/ModelTankStoreDia.vue';
import { machineOrderTypes, getSortForm } from './define'
export default {
  name: "ModelRankTable",
  components: { ModelTankHighDia, ModelTankStoreDia },
  data() {
    return {
      loading: false,
      list: [],
      form: {},
      sortForm: {},
      sort: {}
    }
  },
  methods: {
    init(form) {
      this.form = JSON.parse(JSON.stringify(form))
      this.getData()
    },
    getData() {
      this.loading = true
      _api.machineRankStatistics({
        ...this.form,
        ...this.sortForm,
        pageSize: 99999
      }).then(res => {
        this.list = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    handleSortChange({ order, prop }) {
      this.sort = { order, prop }
      const sortForm = getSortForm({ order, prop }, machineOrderTypes)
      this.sortForm = sortForm
      this.getData()
    },
  }
}
</script>