<template>
  <div class="shadow" v-if="visible">
    <div class="btn" @click="confirm">点击查看</div>
  </div>
</template>
<script>
export default {
  name: 'Shadow',
  data() {
    return {
      visible: true
    }
  },
  methods: {
    confirm() {
      this.visible = false
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #888B9B;
  border-radius: 10px;
  opacity: 0.4;
  z-index: 100;
}
.btn{
  width: 140px;
  height: 51px;
  border-radius: 26px;
  border: 2px solid #FFFFFF;

  font-family: PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>