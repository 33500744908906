<template>
  <div class="home" :style="isHome ? '' : 'height:100%;'">
    <div v-if="isHome">
      <!-- <topTitle /> -->
      <div style="overflow: hidden">
        <orderModel />
      </div>
      <div>
        <Settle />
      </div>
      <div class="HomeModel">
        <echartsOrder />
      </div>
      <div class="HomeModel">
        <echartsDeal   :companySelectList="companySelectList" :merchantSelectList="merchantSelectList"/>
      </div>
      <!-- 新增：品牌价位段统计 -->
      <div class="HomeModel">
        <BrandPrice :merchantSelectList="merchantSelectList" :companySelectList="companySelectList"/>
      </div>
      <div class="HomeModel">
        <TableOrder :merchantSelectList="merchantSelectList"/>
      </div>
      <div class="HomeModel">
        <TableRecall :companySelectList="companySelectList"/>
      </div>
    </div>
    <div class="error-page" v-else>
      <div class="error-page-conent">
        <img
          style="width: 170px; height: 170px"
          src="@/assets/home/error_img.png"
          alt=""
        />
        <div class="error-tip">~您暂无查看权限~</div>
      </div>
    </div>
  </div>
</template>

<script>
// import topTitle from './component/topTitle'
import orderModel from "./component/orderModel";
import Settle from "./component/Settle";
import echartsOrder from "./component/echarts/echartsOrder";
import echartsDeal from "./component/echarts/echartsDeal";
import TableOrder from "./component/TableOrder";
import TableRecall from "./component/TableRecall";
import BrandPrice from "./component/BrandPrice.vue";
import _api from "@/utils/request";
export default {
  components: {
    orderModel,
    Settle,
    echartsOrder,
    echartsDeal,
    TableOrder,
    TableRecall,
    BrandPrice
  },
  name: "home",
  data() {
    return {
      monthOrderPrice:0,//本月订单的月交易额
      isHome: true,
      HomeDataList: [],
      companySelectList: [],
      merchantSelectList: []
    };
  },
  created() {
    let menuList = this.$store.state.tagsView.menuList;
    let flag = false;
    if (menuList.length) {
      menuList.forEach((item) => {
        if (item.path == "/dashboard") {
          flag = true;
        }
      });
      if (!flag) {
        this.isHome = false;
      }else{
        this.isHome = true;
      }
    }

    this.getCompanySelectList()
    this.getMerchantSelectList()
  },
  computed: {
    menuList() {
      return this.$store.state.tagsView.menuList;
    },
  },
  watch: {
    menuList(val) {
      let flag = false;
      val.forEach((item) => {
        if (item.path == "/dashboard") {
          flag = true;
        }
      });
      if (!flag) {
        this.isHome = false;
      }else{
        this.isHome = true;
      }
    },
  },
  methods: {
    getCompanySelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.companySelectList = res.data
        }
      })
    },
    getMerchantSelectList() {
      _api.getMerchantSelectList().then((res) => {
        if (res.code === 1) {
          this.merchantSelectList = res.data
        }
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.home {
  width: 100%;
  .HomeModel {
    padding-top: 20px;
  }
}
.error-page {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-page-conent {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .error-tip {
    text-align: center;
    color: #0c82ff;
    font-size: 18px;
  }
}
</style>
