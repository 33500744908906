// label暂未使用，但是可能会使用，label为文档上显示文字
export const brandOrPriceOrderTypes = [
  { label: '成交量', prop: 'dataCount', value: '01' },
  { label: '询价量', prop: 'inquiryCount', value: '02' },
  { label: '成交率', prop: 'dataRate', value: '03' },
  { label: '累计成交额', prop: 'dealAmount', value: '04' },
  { label: '平均成交价', prop: 'avgPrice', value: '05' },
  { label: '系统估价量', prop: 'assessCount', value: '06' },
]
export const brandOrPriceOrderDownTypes = [
  { label: '成交量', prop: 'dataCount', value: '01' },
  { label: '询价量', prop: 'inquiryCount', value: '02' },
  { label: '成交率', prop: 'dataRate', value: '03' },
  { label: '累计成交额', prop: 'dealAmount', value: '04' },
  { label: '平均成交价', prop: 'avgPrice', value: '05' },
  { label: '系统估价量', prop: 'assessCount', value: '06' },
  { label: '报价率', prop: 'quoteRate', value: '07' },
  { label: '补贴金额', prop: 'renewSubsidyPriceAll', value: '08' },
  { label: '报价量', prop: 'quoteCount', value: '09' },
  { label: '有效报价量', prop: 'normalQuoteNum', value: '10' },
  { label: '拒绝报价量', prop: 'rejectQuoteNum', value: '11' },
  { label: '低价拒绝量', prop: 'rejectLowQuoteNum', value: '12' },
]

export const machineOrderTypes = [
  { label: '成交量', prop: 'dealCount', value: '01' },
  { label: '询价量', prop: 'inquiryCount', value: '02' },
  { label: '成交率', prop: 'dealRate', value: '03' },
  { label: '估价单询价转化率', prop: 'assessToInquiryRate', value: '04' },
  { label: '估价单成交转化率', prop: 'assessToDealRate', value: '05' },
  { label: '系统估价量', prop: 'assessCount', value: '06' },
]
export const machineInquiryOrderTypes = [
  { label: '成交量', prop: 'dealCount', value: '01' },
  { label: '询价量', prop: 'inquiryCount', value: '02' },
  { label: '成交率', prop: 'dealRate', value: '03' },
  { label: '估价单询价转化率', prop: 'assessToInquiryRate', value: '04' },
  { label: '估价单成交转化率', prop: 'assessToDealRate', value: '05' },
  { label: '系统估价量', prop: 'assessCount', value: '06' },
  { label: '累计交易额', prop: 'dealAmount', value: '07' },
  { label: '换新补贴金额', prop: 'renewSubsidyPriceAll', value: '08' },
  { label: '回收毛利', prop: 'dealProfit', value: '09' },
  { label: '公司净利', prop: 'netRebateAmount', value: '10' },
]
export const machineQuoteOrderTypes = [
  { label: '成交量', prop: 'dealCount', value: '01' },
  { label: '推送订单数量', prop: 'inquiryCount', value: '02' },
  { label: '报价量', prop: 'quoteCount', value: '03' },
  { label: '有效报价量', prop: 'normalQuoteNum', value: '10' },
  { label: '报价率', prop: 'quoteRate', value: '04' },
  { label: '最高报价量', prop: 'maxQuoteCount', value: '05' },
  { label: '最高报价率', prop: 'maxQuoteRate', value: '06' },
  { label: '拒绝报价量', prop: 'rejectQuoteCount', value: '07' },
  { label: '成交率', prop: 'dealRate', value: '08' },
  { label: '累计成交金额', prop: 'dealAmount', value: '09' },
  { label: '拒绝报价量', prop: 'rejectQuoteNum', value: '11' },
  { label: '低价拒绝量', prop: 'rejectLowQuoteNum', value: '12' },
]

export function getSortForm({ order, prop }, types) {
  const formData = {
    ackOrDesc: undefined, // 降序：01，升序：02
    orderType: undefined
  }
  if (order !== null) {
    switch(order) {
      // case false:
      case 'ascending':
        formData.ackOrDesc = '02'
        break;
      // case true:
      case 'descending':
        formData.ackOrDesc = '01'
        break;
    }
    const findType = types.find(t => t.prop === prop)
    if (findType) formData.orderType = findType.value
  }
  return formData
}