<template>
  <el-dialog
    :title="queryType == '01' ? '门店询价订单' : '最高报价订单'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="1100px"
  >
    <div style="margin-bottom: 20px">
      <span>机型：{{ modelInfo.modelName }}</span>
      <span style="margin-left: 30px">时间范围：{{ showData() }}</span>
    </div>
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      isIndex
      :columns="columns"
      :data="list"
      :currentPage="filterForm.pageNum"
      :pageSize="filterForm.pageSize"
      :total="total"
      @handleCurrentChange="getData"
    >
      <el-table-column label="订单号" prop="orderNo" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary">{{ row.orderNo }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" prop="orderState" slot="orderState" align="center">
        <template slot-scope="{ row }">
          {{ row.orderState | optionsFilter(statusOptions) }}
        </template>
      </el-table-column>
      <el-table-column label="询价门店" prop="storeName" slot="storeName" align="center">
        <template slot-scope="{ row }">
          {{ row.storeName || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="询价店员" prop="staffName" slot="staffName" align="center">
        <template slot-scope="{ row }">
          {{ row.staffName }}-{{ row.staffMobile }}
        </template>
      </el-table-column>
      <el-table-column label="最高报价" prop="maxQuotePrice" slot="maxQuotePrice" align="center">
        <template slot-scope="{ row }">
          {{ row.maxQuotePrice || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="用户成交价" prop="finalPrice" slot="finalPrice" align="center">
        <template slot-scope="{ row }">
          {{ row.finalPrice || '--' }}
        </template>
      </el-table-column>
    </GlobalTable>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "ModelTankOrderDia",
  inject: ['showData'],
  props: {
    queryType: String,
    modelInfo: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  filters: {
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10,
        ackOrDesc: undefined,
        orderType: undefined
      },
      total: 0,
      list: [],
      columns: [
        { slotName: "orderNo",  },
        { slotName: "orderState" },
        { label: "门店商家", prop: "companyName" },
        { slotName: "storeName" },
        { slotName: "staffName" },
        { slotName: "maxQuotePrice" },
        { slotName: "finalPrice" }
      ],
      statusOptions: [
        { label:'待确认', value: '00'},
        { label:'已确认', value: '01'},
        { label:'待支付', value: '02'},
        { label:'已绑码', value: '03'},
        { label:'已收货', value: '04'},
        { label:'降价收货', value: '05'},
        { label:'已取消', value: '10'},
        { label:'已作废', value: '20'},
        { label:'已退回', value: '30'},
      ]
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.visible = true
      this.getData()
    },
    getData(pageNum = 1) {
      this.loading = true
      _api.machineRankStatisticsOrderList({
        ...this.form,
        ...this.filterForm,
        pageNum,
        queryType: this.queryType,
        modelId: this.modelInfo.modelId,
        merchantId: this.row.merchantId,
        companyId: this.row.companyId,
      }).then(res => {
        this.list = res.data.records
        this.filterForm.pageNum = res.data.current
        this.total = res.data.total
      }).finally(() => {
        this.loading = false
      })
    }
  }
};
</script>