<template>
  <div>
    <el-dialog
      title="门店询价分布"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="1200px"
    >
      <div style="margin-bottom: 20px">
        <span>{{ className() }}-{{ row.brandName }}</span>
        <span style="margin-left: 30px">机型：{{ row.modelName }}</span>
      </div>
      <el-table v-if="visible" v-loading="loading" :data="list" :default-sort="sort" border @sort-change="handleSortChange">
        <el-table-column type="index" label="序号" width="70" align="center" :index="(index)=> (index+1) + (filterForm.pageNum-1)*filterForm.pageSize" />
        <el-table-column label="门店商名称" prop="companyName" align="center" />
        <el-table-column label="系统估价量" prop="assessCount" align="center" />
        <el-table-column label="估价单询价转化率" prop="assessToInquiryRate" align="center">
          <template slot-scope="{ row }">
            {{ row.assessToInquiryRate }}%
          </template>
        </el-table-column>
        <el-table-column label="估价单成交转化率" prop="assessToDealRate" align="center">
          <template slot-scope="{ row }">
            {{ row.assessToDealRate }}%
          </template>
        </el-table-column>
        <el-table-column label="询价量" prop="inquiryCount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="成交量" prop="dealCount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="成交率" prop="dealRate" align="center" sortable="custom" :sort-orders="['descending', 'ascending']">
          <template slot-scope="{ row }">
            {{ row.dealRate }}%
          </template>
        </el-table-column>
        <el-table-column label="累计交易额" prop="dealAmount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="换新补贴金额" prop="renewSubsidyPriceAll" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="回收毛利" prop="dealProfit" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="公司净利" prop="netRebateAmount" align="center" sortable="custom" :sort-orders="['descending', 'ascending']" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="$refs['modelTankOrderDia'].open(row)">订单明细</el-link>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :pageNum.sync="filterForm.pageNum" :pageSize.sync="filterForm.pageSize" :total="total" @pagination="getData" />
    </el-dialog>
    <ModelTankOrderDia ref="modelTankOrderDia" queryType="01" :modelInfo="row" :form="form" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import { machineInquiryOrderTypes, getSortForm } from '../table/define'
import ModelTankOrderDia from './ModelTankOrderDia.vue';
import Pagination from '../Pagination.vue';
export default {
  name: "ModelTankStoreDia",
  components: { ModelTankOrderDia, Pagination },
  inject: ['className'],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    sort: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      row: {},
      list: [],
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      sortForm: {},
      total: 0,
      // columns: [
      //   { label: "门店商名称", prop: "companyName" },
      //   { label: "系统估价量", prop: "assessCount" },
      //   { label: "估价单询价转化率", prop: "assessToInquiryRate" },
      //   { label: "估价单成交转化率", prop: "assessToDealRate" },
      //   { label: "询价量", prop: "inquiryCount" },
      //   { label: "成交量", prop: "dealCount" },
      //   { label: "成交率", prop: "dealRate" },
      //   { label: "累计交易额", prop: "dealAmount" },
      //   { label: "换新补贴金额", prop: "renewSubsidyPriceAll" },
      //   { label: "回收毛利", prop: "dealProfit" },
      //   { label: "公司净利", prop: "netRebateAmount" },
      //   { slotName: "operation" },
      // ],
    };
  },
  methods: {
    open(row) {
      this.row = row
      this.sortForm = getSortForm(this.sort, machineInquiryOrderTypes)
      this.pageNum = 1
      this.visible = true
      this.getData()
    },
    getData() {
      this.loading = true
      _api.machineRankStatisticsInquiry({
        ...this.form,
        ...this.filterForm,
        ...this.sortForm,
        modelId: this.row.modelId,
      }).then(res => {
        this.list = res.data.records
        this.total = res.data.total
      }).finally(() => {
        this.loading = false
      })
    },
    handleSortChange({ order, prop }) {
      // this.sort = { order, prop }

      const sortForm = getSortForm({ order, prop }, machineInquiryOrderTypes)
      this.sortForm = sortForm
      this.getData()
    },
  }
};
</script>