<template>
  <div class="orderModel">
    <div style="width: 33.3%; position: relative">
      <div class="firstBG modelbg">
        <div class="title">
          <img
            class="case-title_ico"
            src="@/assets/images/imgAll/标签.png"
            alt
          />
        </div>
        <div class="center">
          <div class="todayOrder today">
            <div>今日订单</div>
            <div class="NumberColor_First">
              {{ dayDataList.orderCount }}
              <div
                style="
                  font-size: 16px;
                  margin-left: 10px;
                  color: #0981ff;
                  margin-top: 10px;
                "
              >
                (个)
              </div>
            </div>
          </div>
          <div class="todayMoney today">
            <div>日交易额</div>
            <div class="NumberColor_First">
              {{ dayDataList.orderPrice }}
              <div style="font-size: 16px; margin-top: 10px; color: #0981ff">
                (元)
              </div>
            </div>
          </div>
          <div class="todayMoney today">
            <div>今日询价</div>
            <div class="NumberColor_First">
              {{ dayDataList.inquiryOrderCount }}
              <div style="font-size: 16px; margin-top: 10px; color: #0981ff">
                (单)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow" v-if="dayshow">
        <div class="btn" @click="confirm('01')">点击查看</div>
      </div>
    </div>
    <div style="width: 33.3%; position: relative">
      <div class="SecBG modelbg">
        <div class="title">
          <img
            class="case-title_ico"
            src="@/assets/images/imgAll/icon_bq_zr.png"
            alt
          />
        </div>
        <div class="center">
          <div class="todayOrder today">
            <div>昨日订单</div>
            <div class="NumberColor_Sec">
              {{ yestdayDataList.orderCount }}
              <div
                style="
                  font-size: 16px;
                  margin-left: 10px;
                  color: #ffaf29;
                  margin-top: 10px;
                "
              >
                (个)
              </div>
            </div>
          </div>
          <div class="todayMoney today">
            <div>日交易额</div>
            <div class="NumberColor_Sec">
              {{ yestdayDataList.orderPrice }}
              <div style="font-size: 16px; margin-top: 10px; color: #ffaf29">
                (元)
              </div>
            </div>
          </div>
          <div class="todayMoney today">
            <div>昨日询价</div>
            <div class="NumberColor_Sec">
              {{ yestdayDataList.inquiryOrderCount }}
              <div style="font-size: 16px; margin-top: 10px; color: #ffaf29">
                (单)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow" v-if="yestershow">
        <div class="btn" @click="confirm('02')">点击查看</div>
      </div>
    </div>
    <div style="width: 33.3%; position: relative">
      <div class="ThirdBG modelbg">
        <div class="title">
          <img
            class="case-title_ico"
            src="@/assets/images/imgAll/icon_bzbq_.png"
            alt
          />
        </div>
        <div class="center">
          <div class="todayOrder today">
            <div>本周订单</div>
            <div class="NumberColor_Third">
              {{ weekDataList.orderCount }}
              <div
                style="
                  font-size: 16px;
                  margin-left: 10px;
                  color: #ff687b;
                  margin-top: 10px;
                "
              >
                (个)
              </div>
            </div>
          </div>
          <div class="todayMoney today">
            <div>周交易额</div>
            <div class="NumberColor_Third">
              {{ weekDataList.orderPrice }}
              <div style="font-size: 16px; margin-top: 10px; color: #ff687b">
                (元)
              </div>
            </div>
          </div>
          <div class="todayMoney today">
            <div>本周询价</div>
            <div class="NumberColor_Third">
              {{ weekDataList.inquiryOrderCount }}
              <div style="font-size: 16px; margin-top: 10px; color: #ff687b">
                (单)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow" v-if="weekshow">
        <div class="btn" @click="confirm('03')">点击查看</div>
      </div>
    </div>
  </div>
</template>
<script>
// import Shadow from "./components/Shadow.vue";
import _api from "@/utils/request";
export default {
  name: "orderModel",
  components: {
    // Shadow,
  },
  data() {
    return {
      dayshow: true,
      yestershow: true,
      weekshow: true,
      dayDataList: {},
      yestdayDataList: {},
      weekDataList: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    confirm(type) {
      if (type == "01") {
        this.dayshow = false;
      } else if (type == "02") {
        this.yestershow = false;
      } else {
        this.weekshow = false;
      }
      this.platformDataStatisticsBy(type);
    },
    // 订单总数查询
    // dateType	时间类型 00 全部时间 01：今日，02：昨日，03：本周，04：上周，05：本月 06 上月
    platformDataStatisticsBy(dateType) {
      _api.platformDataStatisticsBy({ dateType }).then((res) => {
        if (res.code === 1) {
          if (dateType == "01") {
            this.dayDataList = res.data;
          } else if (dateType == "02") {
            this.yestdayDataList = res.data;
          } else {
            this.weekDataList = res.data;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.shadow {
  position: absolute;
  width: 95%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  left: 15px;
  background: #888b9b;
  border-radius: 10px;
  opacity: 0.4;
  z-index: 100;
}
.btn {
  width: 140px;
  height: 51px;
  border-radius: 26px;
  border: 2px solid #ffffff;

  font-family: PingFang SC;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.orderModel {
  display: flex;
  justify-content: space-between;
  width: 102%;
  position: relative;
  left: -15px;
}
.modelbg {
  position: relative;
  width: 100%;
  height: 230px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .center {
    position: absolute;
    top: 45px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 70%;
    margin-left: 35%;
    .today {
      display: flex;
      align-items: center;
      padding: 10px 0 0 0;
      div:first-child {
        width: 25%;
        height: 21px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 41px;
      }
      div:last-child {
        width: 50%;
        height: 39px;
        font-size: 46px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 41px;
      }
    }
  }
}
.NumberColor_First {
  color: #0981ff;
  font-size: 44px !important;
  display: flex;
}
.NumberColor_Sec {
  color: #ffaf29;
  font-size: 44px !important;
  display: flex;
}
.NumberColor_Third {
  color: #ff687b;
  font-size: 44px !important;
  display: flex;
}
.firstBG {
  background-image: url("../../../assets/images/imgAll/img_bjjr_@2x.png");
  .title {
    position: absolute;
    right: 2%;
    top: 7%;
  }
}
.SecBG {
  background-image: url("../../../assets/images/imgAll/img_zrtp@2x.png");
  .title {
    position: absolute;
    right: 2%;
    top: 7%;
  }
}
.ThirdBG {
  background-image: url("../../../assets/images/imgAll/bg_nwysbn_@2x.png");
  .title {
    position: absolute;
    right: 2%;
    top: 7%;
  }
}
</style>
