<template>
  <div class="Storetable">
    <div class="btnStore">
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content">回收商家：</span>
        <el-select
          v-model="seachMerchantId"
          filterable
          clearable
          @change="merchanChange"
          placeholder="请选择"
          class="el-form-group_isRound"
        >
          <el-option
            v-for="item in merchantSelectList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex" style="margin-right: 10px">
        <span style="min-width: max-content">排序方式：</span>
        <el-select
          v-model="orderTypeTable"
          @change="merchanChange"
          placeholder="请选择"
          class="el-form-group_isRound"
        >
          <el-option
            v-for="item in orderTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="time-select">
        <div
          class="btn"
          :class="dateType == '01' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('01')"
        >
          今日
        </div>
        <div
          class="btn"
          :class="dateType == '03' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('03')"
        >
          本周
        </div>
        <div
          class="btn"
          :class="dateType == '05' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('05')"
        >
          本月
        </div>
      </div>
      <div>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <div style="margin-left: 10px">
        <el-button type="primary" @click="seachStore" round>查询</el-button>
      </div>
      <div style="margin-left: 10px">
        <el-button
          icon="el-icon-download"
          type="success"
          round
          @click="exportExcelBusinessFn"
          >导出Excel</el-button
        >
      </div> -->
    </div>
    <div class="btnStore">
      <div class="time-select">
        <span class="selectDate">选择日期：</span>
        <div
          class="btn"
          :class="dateType == '01' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('01')"
        >
          今日
        </div>
        <div
          class="btn"
          :class="dateType == '03' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('03')"
        >
          本周
        </div>
        <div
          class="btn"
          :class="dateType == '05' ? 'btnType2' : 'btnType-after2'"
          @click="dataObtain('05')"
        >
          本月
        </div>
      </div>
      <div>
        <el-date-picker
          v-model="valueTime"
          type="daterange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <div class="selectTime">
        <span class="selectDate">选择时间：</span>
        <el-time-select
          class="timeselect"
          placeholder="开始时间"
          v-model="inDayStartTime"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '24:00',
          }"
        >
        </el-time-select>
      </div>
      <span style="margin: 0 5px">~</span>
      <div>
        <el-time-select
          class="timeselect"
          placeholder="结束时间"
          v-model="inDayEndTime"
          :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '24:00',
            minTime: inDayStartTime,
          }"
        >
        </el-time-select>
      </div>
      <div style="margin-left: 10px">
        <el-button :loading="serching" type="primary" @click="seachStore" round>查询</el-button>
      </div>
      <div style="margin-left: 10px">
        <el-button
          icon="el-icon-download"
          type="success"
          round
          @click="exportExcelBusinessFn"
          >导出Excel</el-button
        >
      </div>
    </div>
    <el-table
      ref="tableList"
      :data="tableData"
      :default-sort="{ prop: 'dealCount', order: 'descending' }"
      @sort-change="sortTable"
      border
      style="width: 100%"
      v-loading="storetableLoading"
    >
      <el-table-column prop="rownum" label="序号" width="70" align="center">
        <template slot-scope="{ row }">
          <div class="numBG_1" v-if="row.rownum === 1">1</div>
          <div class="numBG_2" v-else-if="row.rownum === 2">2</div>
          <div class="numBG_3" v-else-if="row.rownum === 3">3</div>
          <div v-else>{{ row.rownum }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="回收商" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.name }}</span>
          <span class="isdeal1" v-if="row.isOutMerchant">外部</span>
        </template>
      </el-table-column>
      <el-table-column
        label="接收询价量"
        prop="orderTotalNum"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.orderTotalNum || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="累计报价量"
        prop="quoteCount"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            @click="merchantDialog(row, '01')"
            class="tableBtn"
            v-if="row.quoteCount"
          >
            <span style="border-bottom: 1px solid #0981ff">{{
              row.quoteCount
            }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        label="拒绝报价量"
        prop="rejectQuoteNum"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            v-if="row.rejectQuoteNum"
            style="color: red; text-decoration: underline; cursor: pointer"
            @click="seeRejectbid(row)"
          >
            {{ row.rejectQuoteNum }}
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        label="低价拒绝量"
        prop="rejectLowQuoteNum"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-link
            v-if="row.rejectLowQuoteNum"
            type="danger"
            :underline="false"
            >{{ row.rejectLowQuoteNum }}</el-link
          >
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="normalQuoteNum"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot="header">
          有效报价量
          <el-tooltip
            class="item"
            effect="dark"
            content="有效报价量=报价量-拒绝报价量"
            placement="top"
          >
            <i class="el-icon-warning-outline" />
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <template slot-scope="{ row }">
          <span>{{ row.orderTotalNum ||'--'}}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="报价率"
        prop="quoteRatio"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.quoteRatio || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="最高报价率"
        prop="highestQuoteRatio"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.highestQuoteRatio || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="累计成交量"
        prop="dealCount"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            @click="$refs['merchantDealStatisticsDia'].open(row)"
            class="tableBtn"
            v-if="row.dealCount"
          >
            <span style="border-bottom: 1px solid #0981ff">{{
              row.dealCount
            }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="dealPrice"
        label="累计交易额"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="dealRatio"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        align="center"
      >
        <template slot="header">
          成交率
          <el-tooltip
            class="item"
            effect="dark"
            content="成交率=成交量/有效报价量*100%"
            placement="top"
          >
            <i class="el-icon-warning-outline" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="avgPrice"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        label="平均成交单价（元）"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="avgSpeed"
        sortable="custom"
        :sort-orders="['descending', 'ascending']"
        label="平均报价速度（S）"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="250px" fixed="right">
        <template slot-scope="{ row }">
          <el-button type="primary" round @click="seeDetailed(row)" size="small"
            >查看明细</el-button
          >
          <el-button
            type="warning"
            round
            @click="seeFailedbid(row)"
            size="small"
            >竞价失败订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="orderPagina">
      <el-pagination
        float="right"
        background
        :current-page="this.page.pageNum"
        :page-size="this.page.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="this.page.total"
        class="el-pagination-c"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      class="merchant-dialog"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="merchantClose"
      :width="type == '01' ? '900px' : '700px'"
    >
      <div class="flex" style="margin-bottom: 20px" v-if="type == '01'">
        <div class="flex" style="margin-right: 20px">
          <span>排序：</span>
          <el-select v-model="orderBy" size="small" @change="orderByChange">
            <el-option
              v-for="item in orderByOption"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <span>商家名称：</span>
          <el-select
            v-model="companyId"
            filterable
            size="small"
            clearable
            placeholder="请选择或输入商家名称"
          >
            <el-option
              v-for="item in staffList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          style="margin-left: 10px"
          size="small"
          @click="searchSetting"
        >
          查询
        </el-button>
        <div style="margin-left: 50px" v-if="type=='01'">
          <el-button
            icon="el-icon-download"
            type="success"
            size="small"
            round
            @click="exportExcelbaoPrice"
            >导出Excel</el-button
          >
        </div>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="storeloading"
        :columns="storeColumns"
        :maxHeight="500"
        :data="storeDataList"
        :isPagination="false"
        :isIndex="false"
      >
        <el-table-column
          :label="type == '01' ? '门店商家' : '报价师'"
          slot="name"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="storeCount"
          label="合作门店数量"
          slot="storeCount"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.storeCount || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="inquiryCount"
          label="询价量"
          slot="inquiryCount"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.inquiryCount || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteRate"
          label="报价率"
          slot="quoteRate"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.quoteRate || 0 }}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteCount01"
          label="报价量"
          slot="quoteCount01"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <div
              @click="offerDialog(row)"
              class="tableBtn"
              v-if="row.quoteCount"
            >
              <span style="border-bottom: 1px solid #0981ff">
                {{ row.quoteCount }}
              </span>
            </div>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="type == '01'"
          label="拒绝报价量"
          slot="rejectQuoteNum"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-link
              v-if="row.rejectQuoteNum"
              type="danger"
              :underline="false"
              >{{ row.rejectQuoteNum }}</el-link
            >
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="type == '01'"
          label="低价拒绝量"
          slot="rejectLowQuoteNum"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-link
              v-if="row.rejectLowQuoteNum"
              type="danger"
              :underline="false"
              >{{ row.rejectLowQuoteNum }}</el-link
            >
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column
          label="有效报价量"
          slot="normalQuoteNum"
          align="center"
          v-if="type == '01'"
        >
          <template slot-scope="{ row }">
            <span>{{ row.normalQuoteNum }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="报价量"
          slot="quoteCount02"
          align="center"
          v-if="type == '02'"
        >
          <template slot-scope="{ row }">
            <span>{{ row.quoteCount }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="dealCount"
          label="成交量"
          slot="dealCount"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.dealCount || 0 }}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="成交率" prop="dealRate" slot="dealRate" :sortable="type === '01'" align="center"> -->
        <el-table-column
          label="成交率"
          prop="dealRate"
          slot="dealRate"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.dealRate + "%" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="avgSpeed"
          label="报价速度(s)"
          slot="avgSpeed"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.avgSpeed || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="avgDealPrice"
          label="平均成交单价"
          slot="avgDealPrice"
          v-if="type == '01'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.avgDealPrice || 0 }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <!-- <GlobalTable
        ref="GlobalTable"
        v-loading="storeloading"
        :columns="storeColumns"
        :maxHeight="500"
        :data="storeDataList"
        :isPagination="false"
        :isIndex="false"
      >
        <el-table-column
          :label="type == '01' ? '门店商家' : '报价师'"
          slot="name"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="storeCount"
          label="合作门店数量"
          slot="storeCount"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{row.storeCount || 0}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="inquiryCount"
          label="询价量"
          slot="inquiryCount"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{row.inquiryCount || 0}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteRate"
          label="报价率"
          slot="quoteRate"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{row.quoteRate || 0}}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="quoteCount01"
          label="报价量"
          slot="quoteCount01"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <div
              @click="offerDialog(row)"
              class="tableBtn"
              v-if="row.quoteCount"
            >
              <span style="border-bottom: 1px solid #0981ff">
                {{ row.quoteCount }}
              </span>
            </div>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column
          label="报价量"
          slot="quoteCount02"
          align="center"
          v-if="type == '02'"
        >
          <template slot-scope="{ row }">
            <span>{{ row.quoteCount }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="dealCount"
          label="成交量"
          slot="dealCount"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{row.dealCount || 0}}</span>
          </template>
        </el-table-column>

        <el-table-column label="成交率" prop="dealRate" slot="dealRate" :sortable="type === '01'" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.dealRate + '%' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="avgSpeed"
          label="报价速度(s)"
          slot="avgSpeed"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{row.avgSpeed || 0}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="avgDealPrice"
          label="平均成交单价"
          slot="avgDealPrice"
          v-if="type == '01'"
          sortable
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{row.avgDealPrice || 0}}</span>
          </template>
        </el-table-column>
      </GlobalTable> -->
    </el-dialog>
    <DialogTbale
      :show="offerVisible"
      title="门店商家报价统计"
      :columns="DialogTableColumns"
      :data="DialogDataList"
      :currentPage="dialogPage.pageNum"
      :total="dialogPage.total"
      :pageSize="dialogPage.pageSize"
      :width="700"
      :isIndex="false"
      @onClose="onClose"
      @handleCurrentChange="handleCurrentChangeDialog"
    >
      <div
        class="flex flex-jb"
        style="margin-bottom: 20px; color: #0981ff; font-size: 16px"
      >
        <span>{{ companyName }}</span>
        <span style="color: #ff687b; font-size: 16px"
          >('--'表示创建人、子账号、总监等没有门店的角色)</span
        >
      </div>
      <el-table-column label="询价门店" slot="name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.name || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成交率" slot="dealRate" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dealRate + "%" }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <DialogTbale
      :show="detailedShow"
      title="品牌/价位明细"
      :columns="detailedColumns"
      :data="detailedData"
      :currentPage="detailedPage.pageNum"
      :total="detailedPage.total"
      :pageSize="detailedPage.pageSize"
      :width="700"
      :isIndex="false"
      @onClose="onClose"
      @handleCurrentChange="detailedChangeDialog"
    >
      <div class="button_top">
        <div class="faultBtn">
          <div
            :class="this.orderType === '01' ? 'active' : 'none'"
            @click="btnClick('01')"
          >
            旧机品牌排行
          </div>
          <div
            :class="this.orderType === '02' ? 'active' : 'none'"
            @click="btnClick('02')"
          >
            成交价位排行
          </div>
        </div>
      </div>
      <el-table-column
        :label="orderType == '01' ? '旧机品牌' : '成交价位'"
        slot="dataName"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{ row.dataName }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <el-dialog
      title="操作提示"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="550px"
      @closed="commandClosed"
    >
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="commandBtnLoading"
          @click.native="commandSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <MerchantDealStatisticsDia
      ref="merchantDealStatisticsDia"
      :dateType="dateType"
      :dateRange="valueTime"
      :startTime="inDayStartTime"
      :endTime="inDayEndTime"
    />
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from "moment";
import MerchantDealStatisticsDia from "../dialog/MerchantDealStatisticsDia.vue";

export default {
  components: { MerchantDealStatisticsDia },
  name: "Storetable",
  props: ["merchantSelectList"],
  data() {
    return {
      serching:false,
      inDayEndTime: "",
      inDayStartTime: "",
      storeloading: false,
      storetableLoading: false,
      commandShow: false,
      commandBtnLoading: false,
      command: "",
      tableData: [],
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      dialogPage: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      valueTime: "",
      startTime: "",
      endTime: "",
      columnType: "04", // 排序类别【接收询价量：01，累计报价量：02，报价率：03，累计成交量：04，累计成交额：05，成交率：06，平均成交价：07，平均报价速度：08】
      ackOrDesc: "01", // 降序：01，升序：02
      dateType: "01",
      type: "",
      staffList: [],
      companyId: "",
      orderBy: "01",
      companyName: "",
      offerCompanyId: "",
      merchantId: "",
      orderType: "01",
      orderTypeTable: "04",
      dialogTitle: "",
      detailedShow: false,
      dialogVisible: false,
      offerVisible: false,
      DialogDataList: [],
      storeDataList: [],
      orderByOption: [
        {
          text: "按成交率由高到低",
          value: "01",
        },
        {
          text: "按报价率由高到低",
          value: "02",
        },
        {
          text: "按报价速度快到慢",
          value: "03",
        },
        {
          text: "平均成交单价高到低",
          value: "04",
        },
        {
          text: "按报价量由高到低",
          value: "05",
        },
        {
          text: "按成交量由高到低",
          value: "06",
        },
      ],
      storeColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "name" },
        { slotName: "storeCount" },
        { slotName: "inquiryCount" },
        { slotName: "quoteCount01" },
        { slotName: "rejectQuoteNum" },
        { slotName: "rejectLowQuoteNum" },
        { slotName: "normalQuoteNum" },
        { slotName: "quoteRate" },
        { slotName: "quoteCount02" },
        { slotName: "dealCount" },
        // { label: "成交量", prop: "dealCount",sortable:true },
        { slotName: "dealRate" },
        { slotName: "avgSpeed" },
        { slotName: "avgDealPrice" },
      ],
      DialogTableColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "name" },
        { label: "报价量", prop: "quoteCount" },
        { label: "有效报价量", prop: "normalQuoteNum" },
        { label: "成交量", prop: "dealCount" },
        { slotName: "dealRate" },
      ],
      detailedData: [],
      detailedColumns: [
        { label: "排行", prop: "rank" },
        { slotName: "dataName" },
        { label: "累计成交量", prop: "dealCount" },
        { label: "累计成交金额（元）", prop: "dealPrice" },
      ],
      orderTypeOption: [
        {
          label: "按成交量由高到低",
          value: "04",
        },
        {
          label: "按报价量由高到低",
          value: "02",
        },
        {
          label: "按交易额由高到低",
          value: "05",
        },
        {
          label: "按成交率由高到低",
          value: "06",
        },
        {
          label: "自定义排序",
          value: "00",
        },
      ],
      rowId: "",
      detailedPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      seachMerchantId: "",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    // 列表排序
    sortTable(column) {
      console.log("===", column);

      if (column.prop) {
        // 排序方式
        if (column.order == "ascending") {
          this.ackOrDesc = "02";
        } else if (column.order == "descending") {
          this.ackOrDesc = "01";
        }

        // 排序字段
        switch (column.prop) {
          case "orderTotalNum":
            this.columnType = "01";
            break;
          case "quoteCount":
            this.columnType = "02";
            break;
          case "quoteRatio":
            this.columnType = "03";
            break;
          case "dealCount":
            this.columnType = "04";
            break;
          case "dealPrice":
            this.columnType = "05";
            break;
          case "dealRatio":
            this.columnType = "06";
            break;
          case "avgPrice":
            this.columnType = "07";
            break;
          case "avgSpeed":
            this.columnType = "08";
            break;
          case "highestQuoteRatio":
            this.columnType = "09";
            break;
          case "normalQuoteNum":
            this.columnType = "10";
            break;
          case "rejectQuoteNum":
            this.columnType = "11";
            break;
          case "rejectLowQuoteNum":
            this.columnType = "12";
            break;
          default:
            this.columnType = "04";
        }

        this.orderTypeTable = "00";
      }
      this.handleCurrentChange();
    },

    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      if (val) {
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }

      if (this.inDayStartTime && !this.inDayEndTime) {
        this.serching = false
        return this.$message.error("请选择结束时间");
      } else if (!this.inDayStartTime && this.inDayEndTime) {
        this.serching = false
        return this.$message.error("请选择开始时间");
      }

      const baseRequest = {
        inDayStartTime: this.inDayStartTime ? this.inDayStartTime + ":00" : "",
        inDayEndTime: this.inDayEndTime
          ? this.inDayEndTime.slice(0, 2) < "24"
            ? this.inDayEndTime + ":59"
            : this.inDayEndTime + ":00"
          : "",
        ackOrDesc: this.ackOrDesc,
        orderType: this.columnType,
        // command: "",
        dateType: this.dateType,
        endTime: this.endTime,
        merchantId: this.seachMerchantId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        startTime: this.startTime,
      };
      // const baseRequest = {
      //   type: "01",
      //   merchantId: this.seachMerchantId,
      //   startTime: this.startTime,
      //   endTime: this.endTime,
      //   pageNum: this.page.pageNum,
      //   pageSize: this.page.pageSize,
      //   dateType: this.dateType,
      //   orderType: this.orderTypeTable,
      // };
      this.storetableLoading = true;
      this.paramsData = baseRequest;
      const loading = this.$loading({
        lock: true,
        text: "正在加载数据中，请耐心等待！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      _api
        .HomeOrderList(baseRequest)
        .then((res) => {
          if (res.code === 1) {
            this.serching=false
            this.tableData = res.data.records;
            this.page.pageNum = res.data.current;
            this.page.total = res.data.total;
          }
          loading.close();
          this.storetableLoading = false;
          this.serching = false
        })
        .catch((ree) => {
          this.serching = false
          loading.close();
        });
    },
    getAllMiddleAdoptCompany() {
      _api
        .getAllMiddleAdoptCompany({ merchantId: this.merchantId })
        .then((res) => {
          if (res.code === 1) {
            this.staffList = res.data;
          }
        });
    },
    //时间选择点击事件
    dataObtain(way) {
      this.dateType = way;
      this.valueTime = "";
      this.startTime = "";
      this.endTime = "";
      this.handleCurrentChange();
    },
    seachStore() {
      if (this.valueTime !== null && this.valueTime) {
        (this.dateType = ""),
          (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        if (!this.dateType) {
          (this.dateType = "01"), (this.startTime = ""), (this.endTime = "");
        }
      }
      this.serching=true
      this.handleCurrentChange();
    },
    merchantDialog(row, type) {
      console.log(row);
      this.merchantId = row.id;
      this.type = type;
      if (type == "01") {
        this.dialogTitle = "回收商家报价统计";
      } else {
        this.dialogTitle = "回收商家成交统计";
      }
      this.dialogVisible = true;
      this.getAllMiddleAdoptCompany();
      this.getMerchantDeal();
    },
    orderByChange() {
      this.searchSetting();
    },
    getMerchantDeal() {
      const baseRequest = {
        type: this.type,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType: this.dateType,
        merchantId: this.merchantId,
        companyId: this.companyId,
        orderBy: this.orderBy,
      };
      this.storeloading = true;
      _api.merchantDealStatistics(baseRequest).then((res) => {
        if (res.code === 1) {
          this.storeDataList = res.data;
          this.storeloading = false;
        }
      });
    },
    searchSetting() {
      this.getMerchantDeal();
    },
    onClose() {
      this.dialogPage.pageNum = 1;
      this.offerVisible = false;
      this.detailedShow = false;
    },
    merchantClose() {
      this.dialogVisible = false;
      this.companyId = "";
    },
    offerDialog(row) {
      console.log(row);
      this.offerCompanyId = row.id;
      this.companyName = row.name;
      this.offerVisible = true;
      this.handleCurrentChangeDialog();
    },
    handleCurrentChangeDialog(val) {
      console.log(val);
      if (val) {
        this.dialogPage.pageNum = val;
      } else {
        this.dialogPage.pageNum = 1;
      }
      const baseRequest = {
        startTime: this.startTime,
        endTime: this.endTime,
        dateType: this.dateType,
        merchantId: this.merchantId,
        companyId: this.offerCompanyId,
        pageNum: this.dialogPage.pageNum,
        pageSize: this.dialogPage.pageSize,
      };
      _api.merchantDealByCompany(baseRequest).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.dialogPage.pageNum = res.data.current;
          this.dialogPage.total = res.data.total;
        }
      });
    },
    // 查看明细
    seeDetailed(row) {
      this.rowId = row.id;
      this.detailedChangeDialog();
      this.detailedShow = true;
    },
    //竞价失败订单
    seeFailedbid(row) {
      const { href } = this.$router.resolve({
        path: "/RecallOrder/failedBidorder",
        query: {
          id: row.id,
        },
      });
      window.open(href, "_blank");
    },
    //拒绝订单
    seeRejectbid(row) {
      const { href } = this.$router.resolve({
        path: "/RecallOrder/rejuectOrder",
        query: {
          id: row.id,
          name: row.name,
        },
      });
      window.open(href, "_blank");
    },
    btnClick(val) {
      this.orderType = val;
      this.detailedChangeDialog();
    },
    detailedChangeDialog(val) {
      if (val) {
        this.detailedPage.pageNum = val;
      } else {
        this.detailedPage.pageNum = 1;
      }
      let params = {
        dateType: this.dateType,
        startTime: this.startTime,
        endTime: this.endTime,
        merchantId: this.rowId,
        orderType: this.orderType,
        type: "01",
        pageNum: this.detailedPage.pageNum,
        pageSize: this.detailedPage.pageSize,
      };
      _api.merchantOrderRankBy(params).then((res) => {
        if (res.code === 1) {
          this.detailedData = res.data.records;
          this.detailedPage.pageNum = res.data.current;
          this.detailedPage.total = res.data.total;
        }
      });
    },
    merchanChange() {
      this.$refs.tableList.clearSort();
      this.ackOrDesc = "01";
      this.columnType = this.orderTypeTable;
      this.seachStore();
    },
    exportExcelBusinessFn() {
      this.commandShow = true;
    },
    commandClosed() {
      this.commandShow = false;
      this.command = "";
    },
    commandSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.exportExcelBusiness();
    },
    exportExcelbaoPrice() {
      let data = {
        type: "01",
        orderBy:this.orderBy,
        companyId:this.companyId,
        merchantId:this.merchantId,
        dateType:this.paramsData.dateType,
        startTime:this.paramsData.startTime,
        endTime:this.paramsData.endTime,
      };
      _api.merchantDealStatisticsExcel(data).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
            }
          } catch (err) {
            const fileName = `回收商报价统计.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandShow = false;
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    exportExcelBusiness() {
      let data = {
        type: "01",
        command: this.command,
        ...this.paramsData,
      };
      this.commandBtnLoading = true;
      _api.platformOrderRankListExcel(data).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.commandBtnLoading = false;
            }
          } catch (err) {
            let start = "";
            let end = "";
            if (data.startTime || data.endTime) {
              start = moment(Number(data.startTime)).format("YYYY.MM.DD");
              end = moment(Number(data.endTime)).format("YYYY.MM.DD");
            } else if (data.dateType == "01") {
              start = moment().month("YYYY-MM-DD").format("YYYY.MM.DD");
            } else if (data.dateType == "03") {
              start = moment().weekday(1).format("YYYY.MM.DD");
              end = moment().month("YYYY-MM-DD").format("YYYY.MM.DD");
            } else if (data.dateType == "05") {
              start = moment().startOf("month").format("YYYY.MM.DD");
              end = moment().month("YYYY-MM-DD").format("YYYY.MM.DD");
            }
            const fileName = `【回收商交易排行】${start || ""}${
              end ? "-" + end : ""
            }.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
            this.commandBtnLoading = false;
            this.commandShow = false;
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Storetable {
  padding: 0 20px 20px;
  .orderPagina {
    padding: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }
  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }
  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }
  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .btnStore {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 22px 0;
    .time-select {
      display: flex;
      .btn {
        box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
        margin: 0px 10px 0px 0;
        text-align: center;
        cursor: pointer;
      }
      .btnType-after2 {
        // width: 33%;
        width: 80px;
        min-width: max-content;
        height: 40px;
        background: #ffffff;
        color: #0981ff;
        line-height: 40px;
        border-radius: 20px;
      }
      .btnType2 {
        width: 80px;
        min-width: max-content;
        height: 40px;
        background: #0981ff;
        color: white;
        line-height: 40px;
        border-radius: 20px;
      }
    }
  }
  .merchant-dialog {
    /deep/.el-dialog__body {
      padding: 21px 20px 20px 20px;
    }
  }
  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn {
    width: 240px;
    height: 32px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981ff;
      border-radius: 20px;
    }
    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    justify-content: space-between;
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .isdeal1 {
    border: 1px solid #f59a23;
    display: inline-block;
    width: max-content;
    padding: 0px 4px;
    font-size: 12px;
    margin: 0 auto;
    border-radius: 5px;
    color: #f59a23;
    text-align: center;
    margin-left: 5px;
  }
  .selectDate {
    min-width: max-content;
    height: 40px;
    line-height: 40px;
  }
  .selectTime {
    display: flex;
    margin-left: 10px;
  }
  .timeselect.el-date-editor {
    /deep/ .el-input__inner {
      width: 200px;
    }
  }
}
</style>
